//require('jquery');

console.log("Loading custom.js");

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});

$(document).on("turbolinks:load", function(){
  $('[data-toggle="tooltip"]').tooltip();
});

$(document).on('click', "#createUserModal .submit", function(event){
  event.preventDefault();
  var modal = $(this).closest('.modal');
  var form = modal.find('form');
  modal.find('.modal-body').html('<div class="d-flex align-items-center justify-content-center" style="height: 350px;"><i class="fas fa-circle-notch fa-spin fa-2x"></i></div>');
  $.ajax({
    method: 'POST',
    url: form.attr('action'),
    data: form.serialize(),
    dataType: 'json',
    success: function(data){
      if(data.hasErrors){
        modal.find('.modal-body').html(data.view);
      } else {
        modal.modal('hide');
        window.location.reload();
      }
    },
    error: function(data){
      modal.find('.modal-body').html("Request failed!");
    }
  });
});

$(document).on("change", "#filter_options", function(){
  $(this).closest('form').submit();
});

$(function(){
  let emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  function checkFormValidation(){
    let step1Check = true, step2Check = true;
    if($('.user-steps').hasClass('step1-active')){
      if($('#user_first_name').val() === '' || $('#user_last_name').val() === '' || $('#user_email').val() === ''){
        step1Check = false;
      }else{
        step1Check = emailRegex.test($('#user_email').val());
      }
      $('#uFirstname').html($('#user_first_name').val());
      $('#uLastname').html($('#user_last_name').val());
      $('#uEmail').html($('#user_email').val());
      let companyCity = [];
      if($('#user_company').val() !== ''){
        companyCity.push($('#user_company').val());
      }
      if($('#user_city').val() !== ''){
        companyCity.push($('#user_city').val());
      }
      if($("#user_role_id").length){
        $('#uRole').html($('#user_role_id option:selected').text());
      }
      $('#uCompanyCity').html(companyCity.join(", "));

      return step1Check;
    }else if($('.user-steps').hasClass('step2-active')){
      var selectedLang = [];
      if($('.user-steps.step2-active input:checked').length > 0){
        step2Check = true;
        $('.user-steps.step2-active input:checked').each(function(index,el){
          selectedLang.push($(el).parent().text().trim());
        });
        $('#uLanguage').html(selectedLang.join(", "));
      }else{
        step2Check = false;
      }
      return step2Check;
    }
  }
  $(document).on('click','.next-step',function(){
    $('.user-steps').hasClass('step1-active') ? $('.user-steps').removeClass('step1-active step3-active').addClass('step2-active') : $('.user-steps').hasClass('step2-active') ? $('.user-steps').removeClass('step1-active step2-active').addClass('step3-active') : $('.user-steps').removeClass('step2-active step3-active').addClass('step1-active');
    if($('.user-steps').hasClass('step2-active') && $('.user-steps.step2-active input[type="checkbox"]:checked').length > 0){
      $(this).removeClass('disabled');
    }else{
      $(this).addClass('disabled');
    }
  });
  $(document).on('click','.back-step',function(){
    $('.user-steps').hasClass('step2-active') ? $('.user-steps').removeClass('step2-active step3-active').addClass('step1-active') : $('.user-steps').hasClass('step3-active') ? $('.user-steps').removeClass('step1-active step3-active').addClass('step2-active') : $('.user-steps').removeClass('step2-active step3-active').addClass('step1-active');
    $('.user-steps .next-step').removeClass('disabled');
  });
  $(document).on('change','.user-steps input',function(){
    if(checkFormValidation()){
      $('.user-steps .next-step').removeClass('disabled');
    }else{
      console.log('Form not valid');
      $('.user-steps .next-step').addClass('disabled');
    }
  });
  $('.edit-user-wrapper input').on('keyup',function(){
    var getClass = $(this).data('obj');
    $('.'+getClass).html($(this).val());
  });
  $('.edit-user-wrapper input[type="checkbox"]').on('change',function(){
    var uLang = [];
    $('.edit-user-wrapper input[type="checkbox"]:checked').each(function(index,el){
      uLang.push($(el).closest('label').text().trim());
      $('.uLanguage').html(uLang.join(', '));
    });
  });
});

/* Edit translation text modal */
$(document).on('click', "#editTranslationTextModal .update-translatation-text-submit", function(event){
  event.preventDefault();
  var form = $(this).closest('form');
  var container = form.closest('.trans-text');
  if (confirm("Are you sure you want to update translation text?")){
    container.html('<div class="d-flex align-items-center justify-content-center" style="height: 350px;"><i class="fas fa-circle-notch fa-spin fa-2x"></i></div>');
    $.ajax({
      method: 'POST',
      url: form.attr('action'),
      data: form.serialize(),
      success: function(response){
        container.html(response);
      },
      error: function(data){
        container.html("Request failed!");
      }
    });
  }
});
